.image-container {
  display: flex;
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  height: 60vh; /* Ajustez la hauteur selon vos besoins */
}

.centered-image {
  max-width: 100%; /* Ajustez la taille de l'image si nécessaire */
  max-height: 100%; /* Ajustez la taille de l'image si nécessaire */
}